<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      :init-data="initData"
      :form-item-list="formItemList"
      :inline="true"
      @handleChange="handleChange"
      :labelWidth="90"
      round
      :needBtnLoading="true"
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
      <template v-slot:default>
        <el-button
          icon="el-icon-download"
          size="small"
          type="success"
          style="margin-right: 10px;"
          :loading="excelLoading"
          @click="exportExcel"
          >{{ excelLoading ? "正在导出" : "导出Excel" }}
        </el-button>
      </template>
    </GlobalForm>
  </div>
</template>

<script>
import { guideExcelAction } from "@/utils/common";
import moment from "moment";
import _api from "@/utils/request";
import { MessageBox } from "element-ui";
export default {
  name: "Form",
  props: {
    accountType: {
      require: true,
      type: String,
    },
  },
  data() {
    return {
      SeachParams: {},
      ackTime: [],
      excelLoading: false,
      allExcelLoading: false,
      initData: {
        ackTime: [moment().day(moment().day() - 30).format("YYYY-MM-DD 00:00:00"),moment().format("YYYY-MM-DD 23:59:59")],
        state: "",
        orderNo:this.$route.query.order ||""
      },
      // 搜索参数
      formItemList: [
        {
          labelName: "所属商家",
          //此key相当于父
          key: "companyId",
          //需要被清除的key，相当于子
          childrenKey: "storeId",
          type: "selectFilterable",
          placeholder: "请选择或输入商家查询",
          lableWidth: "78px",
          clear: true,
          option: [],
          clearFun: () => false,
        },
        {
          labelName: "下单门店",
          fatherKey: "companyId",
          key: "storeId",
          type: "selectFilterable",
          placeholder: "请先选择门店商家",
          lableWidth: "78px",
          clear: true,
          // 代表可清空
          isClear: true,
          option: [],
          disabled: true,
          clearFun: () => false,
        },
        {
          labelName: "回收商",
          key: "merchantId",
          clear: true,
          type: "selectFilterable",
          placeholder: "请选择或输入商家查询",
          option: [],
          clearFun: () => false,
        },
        {
          labelName: "矩阵成交商家",
          key: "fxMatrixAckMerchantId",
          lableWidth: "120px",
          clear: true,
          type: "selectFilterable",
          placeholder: "请选择或输入商家查询",
          option: [],
          clearFun: () => false,
        },
        {
          labelName: "订单号",
          key: "orderNo",
          type: "input",
          placeholder: "请输入回收单号查询",
        },
        {
          labelName: "旧机串号",
          key: "imei",
          type: "input",
          placeholder: "请输入旧机串号查询",
        },
        {
          labelName: "发货快递单号",
          key: "expressNo",
          type: "input",
          placeholder: "请输入完整快递单号查询",
          lableWidth: "100px",
        },
        {
          labelName: "订单状态",
          key: "state",
          type: "select",
          placeholder: "请选择订单状态",
          option: [
            {
              label: "全部",
              value: "",
            },
            {
              label: "待确认",
              value: "00",
            },
            {
              label: "已确认",
              value: "01",
            },
            {
              label: "待支付",
              value: "02",
            },
            {
              label: "已绑码",
              value: "03",
            },
            {
              label: "已收货",
              value: "04",
            },
            {
              label: "降价收货",
              value: "05",
            },
            {
              label: "已取消",
              value: "10",
            },
            {
              label: "已作废",
              value: "20",
            },
            {
              label: "已退回",
              value: "30",
            },
          ],
        },
        {
          labelName: "外部单号",
          key: "orderNoOneline",
          type: "input",
          placeholder: "请输入外部单号查询",
          lableWidth: "100px",
        },
        {
          labelName: "成交时间",
          key: "ackTime",
          type: "daterange",
        },
        {
          labelName: "收货方",
          key: "deliveryId",
          type: "selectFilterable",
          lableWidth: "80px",
          option: [],
          placeholder: "请选择收货方",
          clear: true,
          clearFun: () => false,
        },
        {
          labelName: "是否外部订单",
          key: "isOutOrder",
          type: "select",
          lableWidth: "120px",
          option: [
            {
              label: "是",
              value: "00",
            },
            {
              label: "否",
              value: "01",
            }
          ],
          placeholder: "请选择是否外部订单",
        },
      ],
    };
  },
  created() {
    let startTime = moment().day(moment().day() - 30).format("YYYY-MM-DD 00:00:00"); // 当前时间往前推15天的时间
    let endTime = moment().format("YYYY-MM-DD 23:59:59");
    this.ackTime = [moment(startTime).format("x"), moment(endTime).format("x")];
    this.getSelectData();
    this.getmercate();
    this.handleConfirm({...this.initData});
  },
  computed:{
    //催货状态
    trackState() {
      return this.$store.state.tagsView.trackState;
    },
  },
  watch: {},
  methods: {
    handleChange(v1, v2) {
      if (v1.key === "companyId") {
        const index = this.formItemList.findIndex(
          (v) => v.key === v1.childrenKey
        );
        if (v2) {
          this.formItemList[index].disabled = false;
          this.getStoreList(v2);
        } else {
          this.formItemList[index].disabled = true;
        }
      }
    },
    // 下拉列表数据
    async getSelectData() {
      const retrieveRes = await _api.listSimpleMerchantView({
        pageNum: 1,
        pageSize: 9999,
      });
      // 回收商家下拉
      if (retrieveRes.code === 1) {
        const hsIndex = this.formItemList.findIndex(
          (v) => v.key === "merchantId"
        );
        this.formItemList[hsIndex].option = retrieveRes.data.map((v) => {
          return {
            label: v.merchantName,
            value: v.merchantId,
          };
        });
        // 矩阵成交商家
        const matruxIndex = this.formItemList.findIndex(
          (v) => v.key === "fxMatrixAckMerchantId"
        );
        this.formItemList[matruxIndex].option = retrieveRes.data.map((v) => {
          return {
            label: v.merchantName,
            value: v.merchantId,
          };
        });
      }
      // 所属商家下拉
      const storeRes = await _api.getSelectList();
      if (storeRes.code === 1) {
        this.formItemList[0].option = storeRes.data.map((v) => {
          return {
            label: v.name,
            value: v.id,
          };
        });
      }
    },
    // 请求下单门店下拉
    getStoreList(companyId) {
      _api.getStoreSelectList({ companyId }).then((r) => {
        const data = r.data || [];
        const index = this.formItemList.findIndex((v) => v.key === "storeId");
        this.formItemList[index].option = data.map((v) => {
          return {
            label: v.value,
            value: v.key,
          };
        });
      });
    },
    // 收货方下拉
    getmercate() {
      _api.listDptmMerchantList({ pageNum: 1, pageSize: 999 }).then((res) => {
        if (res.code === 1) {
          const deliveryIndex = this.formItemList.findIndex(
            (v) => v.key === "deliveryId"
          );
          this.formItemList[deliveryIndex].option= res.data.map((v) => {
            return {
              label: v.merchantName,
              value: v.merchantId,
            };
          })
        }
      });
    },
    handleConfirm(data,cd) {
      console.log(this.initData, "提交了form", data);

      let startTime = "";
      let endTime = "";
      if (data.ackTime === null) {
        (startTime = ""), (endTime = "");
      } else {
        (startTime = moment(data.ackTime[0]).startOf("day").format("x")),
          (endTime = moment(data.ackTime[1]).endOf("day").format("x"));
      }
      this.SeachParams = {
        ackStartTime: startTime,// || this.ackTime[0],
        ackEndTime: endTime,// || this.ackTime[1],
        companyIdad: data.companyId, //所属商家
        storeId: data.storeId,
        expressNo: data.expressNo,
        fxMatrixAckMerchantId: data.fxMatrixAckMerchantId,
        imei: data.imei,
        merchantId: data.merchantId,
        orderNo: data.orderNo,
        orderNoOneline: data.orderNoOneline,
        pageNum: 1,
        pageSize: 10,
        statead: data.state,
        isOutOrder:data.isOutOrder,
        deliveryId:data.deliveryId,
        disuseLoding: () => {
          if (typeof cd === 'function') {
            cd()
          }
        },
      };
      this.$store.commit("tagsView/SEACH_PARAMS", this.SeachParams);
    },
    // 导表
    exportExcel() {
      console.log(this.SeachParams);
      let excelParams = JSON.parse(JSON.stringify(this.SeachParams));
      // if (!excelParams.startTime) {
      //   excelParams.ackStartTime = this.ackTime[0];
      //   excelParams.ackEndTime = this.ackTime[1];
      //   excelParams.pageNum = 1
      //   excelParams.pageSize = 10
      // }
      excelParams.companyId=excelParams.companyIdad
      excelParams.trackState=this.trackState //00催货中  01已完结
      excelParams.state=excelParams.statead
      delete excelParams.statead

      this.excelLoading = true;
      // _api.dptmErrorExcel(excelParams).then((r) => {
      //   // 导表
      //   guideExcelAction(r, `异常催货跟踪`, () => {
      //     this.excelLoading = false;
      //   });
      //   this.$message.success("操作成功");
      // });
      _api.dptmErrorExcel(excelParams).then((res) => {
        const content = res;
        const blob = new Blob([content]);
        let reader = new FileReader(); // 创建读取文件对象
        reader.addEventListener("loadend", () => {
          try {
            let data = JSON.parse(reader.result);
            if (data.code === 0) {
              MessageBox.alert(data.msg, "错误提示", {
                  type: "error",
                });
            }
          } catch (err) {
            const fileName = "异常催货跟踪.xlsx";
            if ("download" in document.createElement("a")) {
              // 非IE下载
              const elink = document.createElement("a");
              elink.download = fileName;
              elink.style.display = "none";
              elink.href = URL.createObjectURL(blob);
              document.body.appendChild(elink);
              elink.click();
              URL.revokeObjectURL(elink.href); // 释放URL 对象
              document.body.removeChild(elink);
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName);
            }
          }
        });
        reader.readAsText(blob, "utf-8");

        this.excelLoading = false
      }).catch(err => {
        this.excelLoading = false
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
