<template>
  <div class="class-list">
    <GlobalInfoBar
      title="异常催货管理"
      content="管理回收商或平台人员上报的异常催货的订单"
    />
    <!-- <Info/> -->
    <GlobalChunk icon="search" title="筛选查询">
      <div slot="filter">
        <From />
      </div>
      <Table />
    </GlobalChunk>
  </div>
</template>

<script>
import From from "./Form";
import Table from "./Table";
import _api from "@/utils/request";
export default {
  name: "class-list",
  components: {
    From,
    Table,
  },
  data() {
    return {
    };
  },
  created(){
  },
  methods: {
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.class-list{
}
</style>